<template>
  <v-container class="tw-mb-10">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
        >
          Add an event to your trip
        </h2>
        <p
          class="tw-text-left tw-text-md tw-text-light-grey tw--mt-6 tw-mb-8"
          v-if="event.type === eventTypes.TRAVEL"
        >
          Your travel details will be added to the travel schedule and trip
          itinerary
        </p>
      </v-col>
    </v-row>
    <v-form @submit.prevent="save">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="Event Name"
              rules="required"
            >
              <j-text-field
                label="Event Description*"
                v-model="event.description"
                :error-messages="errors"
                :hide-details="errors.length === 0"
                :placeholder="getPlaceholder()"
                background-color="#fafafa"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="tw-mt-5">
          <j-chip-group
            label="Event Type*"
            :items="eventTypes"
            :value="$route.params.value || event.type"
            @input="event.type = $event"
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="j-text-field-label">Date*</span>
            <v-expansion-panels
              v-model="isOpen"
              class="tw-rounded-md"
              ref="datePanel"
            >
              <v-expansion-panel ref="datesPanel">
                <v-expansion-panel-header>
                  <div
                    class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                  >
                    <v-icon>mdi-calendar-blank</v-icon>
                    <div
                      class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                    >
                      <span v-if="event.startDate">{{
                        getFormattedDate(event.startDate, "without-time")
                      }}</span>
                      <span v-else>Choose a date</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-date-picker
                    class="tw-py-4"
                    no-title
                    color="secondary"
                    v-model="event.startDate"
                    :events="functionEvents"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="secondary"
                      @click="event.startDate = null"
                      >Reset Date</v-btn
                    >
                  </v-date-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="event.startTime"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="event.startTime"
                type="time"
                :error-messages="errors"
                :label="
                  event.type === eventTypes.TRAVEL
                    ? 'Arrival or Departure Time'
                    : 'Start Time'
                "
                class="tw-mt-2"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="event.endTime"
              rules="required"
              v-if="event.type !== eventTypes.TRAVEL"
            >
              <j-text-field
                color="secondary"
                v-model="event.endTime"
                type="time"
                :error-messages="errors"
                label="End Time"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Location Name"
              v-model="event.nameOfPlace"
              background-color="#fafafa"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Address"
              v-model="event.address"
              background-color="#fafafa"
            />
          </v-col>
        </v-row>
        <v-row v-if="event.type === eventTypes.TRAVEL">
          <v-col cols="12">
            <j-text-field
              label="Flight or Confirmation Number"
              v-model="event.flightNumber"
              background-color="#fafafa"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="event.link"
              rules="max:500"
            >
              <j-text-field
                label="Link"
                v-model="event.link"
                background-color="#fafafa"
                :error-messages="errors"
                :hide-details="errors.length === 0"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <j-text-field
              label="Notes"
              v-model="event.note"
              background-color="#fafafa"
            />
          </v-col>
        </v-row>
        <span class="tw-hidden">{{ invalid }}</span>
      </validation-observer>
    </v-form>
  </v-container>
</template>
<script>
import { DateTime } from "luxon";
import { mapMutations } from "vuex";
import { EVENTS } from "@/enums/events.js";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
export default {
  name: "ItineraryEventCreate",
  mixins: [FormattedDateRange],
  props: ["value"],
  data() {
    return {
      event: {
        nameOfPlace: "",
        description: "",
        type: null,
        address: "",
        link: "",
        note: "",
        tripId: this.$route.params.id,
        flightNumber: "",
        authorId: null,
        startDate: null,
        startTime: null,
        endTime: null
      },
      timeOptions: [],
      isOpen: false,
      eventTypes: EVENTS
    };
  },
  watch: {
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },
  computed: {
    enable() {
      return (
        this.event.startDate &&
        this.event.description.length > 0 &&
        this.event.type
      );
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    // getMinMaxDate(val) {
    //   if (val === "min") {
    //     if (!this.trip || !this.trip.startDate) return null;
    //     const inputDate = DateTime.fromISO(this.trip.startDate, {
    //       setZone: true
    //     });
    //     const resultDate = inputDate.minus({ days: 7 });
    //     return resultDate.toISODate();
    //   } else {
    //     if (!this.trip || !this.trip.endDate) return null;
    //     const inputDate = DateTime.fromISO(this.trip.endDate, {
    //       setZone: true
    //     });
    //     const resultDate = inputDate.plus({ days: 7 });
    //     return resultDate.toISODate();
    //   }
    // },
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    getPlaceholder() {
      if (this.event.type === this.eventTypes.TRAVEL) {
        return "Suzie lands at SFO, drive to NYC, train ride to...";
      }
      return "Food shop, alcohol run, stop at drugstore...";
    },
    functionEvents(date) {
      let availableDates = [];
      if (this.trip.startDate && this.trip.endDate) {
        let startDate = DateTime.fromISO(this.trip.startDate, {
          setZone: true
        }).endOf("day");
        let endDate = DateTime.fromISO(this.trip.endDate, {
          setZone: true
        }).endOf("day");
        for (
          let date = startDate;
          date <= endDate;
          date = date.plus({ days: 1 })
        ) {
          availableDates.push(
            DateTime.fromISO(date, { setZone: true }).toISODate()
          );
        }
      }
      if (availableDates.includes(date)) return ["transparent"];
      return false;
    },
    async save() {
      this.setNavigationButton({ text: "Adding..." });
      try {
        let res = await this.$store.dispatch(
          "itinerary-event/create",
          this.event
        );
        setTimeout(() => {
          this.setNavigationButton({ text: "Add to Itinerary" });
          let prevRoute = localStorage.getItem("previous_route");

          if (prevRoute.includes("travel-schedules")) {
            this.$router.push({ name: "TravelSchedulesListRoute" });
          } else {
            this.$router.push({
              name: "TripItineraryList",
              params: { eventUuid: res.uuid }
            });
          }
        }, 1000);
      } catch (error) {
        this.$store.dispatch("meta/showGlobalAlert", {
          type: "error",
          text: "Error creating event. Please try again.",
          timeout: 2000
        });
        console.error(error);
      }
    }
  },
  async beforeMount() {
    this.setNavigationButton({
      button: true,
      text: "Add to Itinerary",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "TRAVEL & OTHER",
      showBackButton: true
    });
    this.event.tripId = this.$route.params.id;
    this.event.authorId = this.$store.state.auth.user.id;
    if (this.$route.params.activeDate) {
      this.event.startDate = DateTime.fromISO(this.$route.params.activeDate, {
        setZone: true
      }).toISODate();
    } else {
      this.event.startDate = DateTime.fromISO(this.trip.startDate, {
        setZone: true
      }).toISODate();
    }
    if (this.$route.params.value) this.event.type = this.$route.params.value;
  },
  destroyed() {
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
<style>
.v-date-picker-table--date .v-btn--text:has(.v-date-picker-table__events) {
  color: #00acf5;
}
</style>
